import React from 'react';
export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper jrhtw9ph4a-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children:
      './../logo.svg',
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: 'Banner3_0',
        children: '首页',
        className: 'menu-item',
      },
      {
        name: 'linkNav~k6x28kkj4dl',
        to: 'Content8_0',
        children: '设备列表',
        className: 'menu-item',
      },
      {
        name: 'linkNav~k6x2aytdhxh',
        to: 'Content10_0',
        children: '公司地址',
        className: 'menu-item',
      },
      {
        name: 'linkNav~k6x2b3dgyu',
        to: 'Content11_0',
        children: '关于我们',
        className: 'menu-item',
      },
      {
        name: 'linkNav~k6x2b4tcjc',
        to: 'Content12_0',
        children: '联系我们',
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
  Menu: {
    children: [
      {
        name: 'Banner3_0',
        to: 'Banner3_0',
        children: '首页',
        className: 'active menu-item',
      },
      {
        name: 'Content8_0',
        to: 'Content8_0',
        children: '特邀嘉宾',
        className: 'menu-item',
      },
      {
        name: 'Content9_0',
        to: 'Content9_0',
        children: '会议日程',
        className: 'menu-item',
      },
      {
        name: 'Content10_0',
        to: 'Content10_0',
        children: '大会地址',
        className: 'menu-item',
      },
      {
        name: 'Content11_0',
        to: 'Content11_0',
        children: '展台展示',
        className: 'menu-item',
      },
      {
        name: 'Content12_0',
        to: 'Content12_0',
        children: '特别鸣谢',
        className: 'menu-item',
      },
    ],
  },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '江都区金安焊接设备厂',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: (
          <>
            <p>以客户为本</p>
            <br/>
            <p>技术先进&nbsp; 品质第一</p>
          </>
        ),
      },
    ],
  },
};
export const Content80DataSource = {
  wrapper: { className: 'home-page-wrapper content8-wrapper' },
  page: { className: 'home-page content8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <>
            <p>设备列表</p>
          </>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content-wrapper',
    children: [
      {
        name: 'block0',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              './../product/1.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block1',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              './../product/2.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block2',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              './../product/3.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block3',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              './../product/4.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block4',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              './../product/5.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block5',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              './../product/6.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block6',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              './../product/7.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block7',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              './../product/8.jpg',
          },
          title: { className: 'content8-title', children: '' },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
    ],
  },
};
export const Content100DataSource = {
  wrapper: { className: 'home-page-wrapper content10-wrapper' },
  Content: {
    className: 'icon-wrapper',
    children: {
      icon: {
        className: 'icon',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
        name: '主要图标',
      },
      iconShadow: {
        className: 'icon-shadow',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
        name: '图标影阴',
      },
      url: { children: 'https://gaode.com/place/B0FFHS8NAG', name: '跳转地址' },
      title: { children: '公司地址', name: '弹框标题' },
      content: {
        children: '金安焊接设备厂  江苏省扬州市江都区328国道南100米',
        name: '弹框内容',
      },
    },
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <>
            <p>关于我们</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <>
            <p>
              江都区金安焊接设备厂位于美丽富饶的扬州市江都区，交通便利发达，拥有4条高速环绕，离扬州机场仅20分钟路程。我厂是一家专业研制生产各类电阻焊接设备及自动化焊接设备的厂家，主要产品有交流点焊机、凸焊机、对焊机、缝焊机、气弹簧专用对焊机、水槽专用缝焊机、减震器专用缝焊机，汽车油箱专用变频缝焊机、储能点焊机、逆变式点焊机、变频式缝焊机、钎焊机等各类专机各类生产成套设备、产品不但在国内有广大市场，还远销欧美和东南亚各国。大量服务于汽车制造、电子元器件制造、自行车电动车配件制造、发电机制造、船舶制造、冰箱制造、及其他各类五金制造等等诸多行业。一直以来本厂围绕“以客户产品为本”的经营理念、秉承技术先进、品质第一、合理竞争、服务到位的产销原则，赢得广大用户的信任，从而提高了我厂在国内外的知名度。
            </p>
          </>
        ),
        className: 'title-content k6x2u8nxjmj-editor_css',
      },
      {
        name: 'content2',
        children: (
          <>
            <br/>
            <p>
              我们愿与您携手，伴您成为产业的先锋；用我们的专业知识帮助您解决焊接之难题；用我们的精良设备，满足您的焊接要求；用我们的热忱与诚信，竭诚为您服务；用我们彼此的真诚，同创美好的明天。
            </p>
          </>
        ),
        className: 'title-content k6x2u8nxjmj-editor_css',
      },
    ],
  },
  button: {
    className: '',
    children: { a: { className: 'button', href: '#', children: '立即报名' } },
  },
};
export const Content120DataSource = {
  wrapper: { className: 'home-page-wrapper content12-wrapper' },
  page: { className: 'home-page content12' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <>
            <p>联系我们</p>
            <br/>
            <br/>
            <p>联系人：吴经理</p>
            <p>手机：18952769088</p>
            <p>电话：0514-86565378</p>
          </>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'img-wrapper',
    children: [
      // {
      //   name: 'block0',
      //   className: 'block',
      //   md: 8,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'block-content' },
      //     img: {
      //       children:
      //         'https://gw.alipayobjects.com/zos/rmsportal/TFicUVisNHTOEeMYXuQF.svg',
      //     },
      //   },
      // },
      // {
      //   name: 'block1',
      //   className: 'block',
      //   md: 8,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'block-content' },
      //     img: {
      //       children:
      //         'https://gw.alipayobjects.com/zos/rmsportal/hkLGkrlCEkGZeMQlnEkD.svg',
      //     },
      //   },
      // },
      // {
      //   name: 'block2',
      //   className: 'block',
      //   md: 8,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'block-content' },
      //     img: {
      //       children:
      //         'https://gw.alipayobjects.com/zos/rmsportal/bqyPRSZmhvrsfJrBvASi.svg',
      //     },
      //   },
      // },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <>
        <span>
          ©2020-2021&nbsp;<a href="https://www.jdjahj.cn">金安焊接设备</a>&nbsp;All
          Rights Reserved
        </span>
      </>
    ),
  },
};
